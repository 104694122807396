import React, { useEffect } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { UpdateInfoUser } from "../../models";
import { useMemberInfo, useProfileData } from "../../services/Query";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../../store";
interface Props {
  show?: boolean;
  onHide?: () => void;
  setUserInfo: (userInfo: any) => void;
}
interface FormValues {
  firstName: string;
  lastName: string;
  typeOfSchool: string;
  schoolName: string;
  country: string;
}

export const AddInfoUserModal = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const userinfo = useRecoilValue(UserInfo);
  const { mutate: onUpdateprofile } = useProfileData();

  const memberID = String(userinfo?.member_Id);

  const {
    data: memberData
  } = useMemberInfo(memberID);
  useEffect(() => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("typeOfSchool", "");
    setValue("schoolName", "");
    setValue("country", "");
  }, []);
  const onSubmit: SubmitHandler<UpdateInfoUser> = async (data) => {
    const formData: FormValues = {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      typeOfSchool: data.typeOfSchool ?? "",
      schoolName: data.schoolName ?? "",
      country: data.country ?? "",
    };
    onUpdateprofile(
      { memberId: memberID, formData: JSON.stringify(formData) },
      {
        onSuccess: (res) => {
          props.setUserInfo(res);
          if (props.onHide) {
            props.onHide();
          }
        },
      }
    );
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      className="dashboard_video"
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Update Information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                First Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("firstName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.firstName}
                autoFocus
              />
              {errors.firstName && (
                <small className="text-danger">
                  First name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                Last Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("lastName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.lastName}
              />
              {errors.lastName && (
                <small className="text-danger">
                  Last name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                Type of School *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("typeOfSchool", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.typeOfSchool}
              />
              {errors.typeOfSchool && (
                <small className="text-danger">
                  Type of school must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">
                School Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("schoolName", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.schoolName}
              />
              {errors.schoolName && (
                <small className="text-danger">
                  School name must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fw-bold form-label">Country *</Form.Label>
              <Form.Control
                type="text"
                {...register("country", {
                  required: true,
                  validate: (value) => value.trim().length >= 3,
                })}
                isInvalid={!!errors.country}
              />
              {errors.country && (
                <small className="text-danger">
                  Country must be at least 3 characters long.
                </small>
              )}
            </Form.Group>

            <div className="d-flex justify-content-center gap-3">
              <button
                type="submit"
                disabled={!isValid}
                className="border_warn mt-3 bg-white rounded_3 py-2 px-4 fs-6"
              >
                Save
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
